import clsx from 'clsx';
import { FC, MouseEventHandler, memo, useCallback, useRef } from 'react';

import styles from './modal-small.module.scss';

import { IModalSmallProps } from './types';

import { Button, EButtonKinds, EModalSize, Modal, Paragraph, Title } from '../ui';

export const ModalSmall: FC<IModalSmallProps> = memo(
  ({
    title,
    isOpen,
    text,
    successBtnText,
    cancellBtnText,
    className = '',
    withCloseBtn,
    isBtnDisabled,
    onClose,
    onSuccess,
    ...rest
  }) => {
    const closeBtnRef = useRef<HTMLButtonElement>(null);

    const handleClose = useCallback(() => {
      closeBtnRef.current?.click();
    }, []);

    const handleSuccess: MouseEventHandler<HTMLButtonElement> = useCallback(
      e => {
        closeBtnRef.current?.click();
        onSuccess?.(e);
      },
      [onSuccess],
    );

    return (
      <Modal
        className={clsx(styles.container, className)}
        isOpen={isOpen}
        onClose={onClose}
        modalSize={EModalSize.SMALL}
        withCloseBtn={withCloseBtn}
        ref={closeBtnRef}
        {...rest}
      >
        <>
          <Title className={styles.title}>{title}</Title>
          <Paragraph className={styles.text}>{text}</Paragraph>
          <div className={styles.btn_box}>
            {successBtnText && (
              <Button
                className={styles.btn}
                text={successBtnText}
                onClick={handleSuccess}
                disabled={isBtnDisabled}
              />
            )}
            {cancellBtnText && (
              <Button
                className={styles.btn}
                text={cancellBtnText}
                onClick={handleClose}
                kind={EButtonKinds.mainSlide}
                disabled={isBtnDisabled}
              />
            )}
          </div>
        </>
      </Modal>
    );
  },
);
