import clsx from 'clsx';
import { FC, memo } from 'react';

import { useTranslation } from 'react-i18next';

import styles from './main-slide.module.scss';
import { IMainSlideProps } from './types';

export const MainSlide: FC<IMainSlideProps> = memo(({ data, className = '', ...rest }) => {
  const { slide, slide_mob: slideMob } = data;

  const { t } = useTranslation();

  return (
    <img
      className={clsx(styles.container, className)}
      src={window.innerWidth > 600 ? slide : slideMob}
      alt={t('alts.main-slide') ?? ''}
      {...rest}
    />
  );
});
