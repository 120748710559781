import clsx from 'clsx';
import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import styles from './photos-box.module.scss';
import { IPhotosBoxProps } from './types';

import { getPhotosOrDafault } from '~utils';

export const PhotosBox: FC<IPhotosBoxProps> = memo(
  ({ className = '', photos, activePhoto, onClick, ...rest }) => {
    const { t } = useTranslation();

    const photosArr = useMemo(() => getPhotosOrDafault(photos), [photos]);

    return (
      <div className={clsx(styles.container, className)} {...rest}>
        {photosArr.map(([key, value], i) => (
          <LazyLoadImage
            className={clsx(styles.img, { [styles.img_active]: activePhoto === key })}
            src={value}
            id={key}
            key={i}
            alt={t('alts.item') || ''}
            onClick={onClick}
          />
        ))}
      </div>
    );
  },
);
