import { TIconProps } from './utils';

export const PhoneIcon = ({ className = '' }: TIconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M15.2458 16.11L16.9258 14.43C17.152 14.2066 17.4383 14.0536 17.7499 13.9897C18.0614 13.9258 18.3848 13.9537 18.6808 14.07L20.7283 14.8875C21.0274 15.0089 21.2838 15.2162 21.4654 15.4831C21.6469 15.75 21.7453 16.0647 21.7483 16.3875V20.1375C21.7465 20.3571 21.7004 20.5741 21.6126 20.7754C21.5248 20.9766 21.3971 21.1581 21.2374 21.3087C21.0776 21.4594 20.889 21.5761 20.683 21.652C20.4769 21.7278 20.2576 21.7612 20.0383 21.75C5.69077 20.8575 2.79577 8.70753 2.24827 4.05753C2.22285 3.82918 2.24608 3.59804 2.31641 3.37931C2.38674 3.16058 2.50258 2.95923 2.65632 2.78848C2.81006 2.61774 2.99821 2.48148 3.20839 2.38868C3.41857 2.29587 3.64602 2.24862 3.87577 2.25003H7.49827C7.82153 2.25099 8.13711 2.34863 8.40442 2.5304C8.67173 2.71217 8.87855 2.96976 8.99827 3.27003L9.81577 5.31753C9.93596 5.61233 9.96662 5.93601 9.90393 6.24814C9.84124 6.56027 9.68797 6.847 9.46327 7.07253L7.78327 8.75253C7.78327 8.75253 8.75077 15.3 15.2458 16.11Z'
      fill='black'
    />
  </svg>
);
