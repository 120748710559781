import { TIconProps } from './utils';

export const FavoriteNavMobIcon = ({ className = '' }: TIconProps) => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    width='27'
    height='25'
    viewBox='0 0 27 25'
    fill='none'
  >
    <path
      d='M13.2146 3.31219L13.5 3.64703L13.7854 3.31219C15.188 1.66659 17.3394 0.625 19.575 0.625C23.5262 0.625 26.625 3.72146 26.625 7.66826C26.625 10.0941 25.5364 12.3247 23.5458 14.7562C21.5509 17.193 18.6895 19.7855 15.2057 22.9417L15.2052 22.9422L13.499 24.494L11.7946 22.9555L11.7938 22.9547L11.7611 22.9251C8.29235 19.7758 5.44291 17.1887 3.45431 14.758C1.46361 12.3248 0.375 10.0942 0.375 7.66826C0.375 3.72146 3.47379 0.625 7.425 0.625C9.66058 0.625 11.812 1.66659 13.2146 3.31219ZM13.765 21.6236L13.8935 21.4953C17.0968 18.5974 19.7814 16.1671 21.6621 13.9716C23.5404 11.7787 24.675 9.75577 24.675 7.66826C24.675 4.76328 22.4818 2.57255 19.575 2.57255C17.4428 2.57255 15.3568 3.87986 14.5184 5.75565H12.4936C11.6437 3.88099 9.55852 2.57255 7.425 2.57255C4.51821 2.57255 2.325 4.76328 2.325 7.66826C2.325 9.75577 3.45959 11.7787 5.33795 13.9716C7.21863 16.1671 9.90315 18.5974 13.1065 21.4953L13.235 21.6236L13.5 21.8884L13.765 21.6236Z'
      fill='white'
      stroke='#1C1C1C'
      strokeWidth='0.75'
    />
  </svg>
);
