import {
  $removeOrderStore,
  endRemoveOrderRequest,
  removeOrderRequestError,
  startRemoveOrderRequest,
} from '.';

$removeOrderStore
  .on(startRemoveOrderRequest, () => ({
    status: 'LOADING',
    data: null,
    error: null,
  }))
  .on(endRemoveOrderRequest, () => ({
    status: 'SUCCESS',
    data: null,
    error: null,
  }))
  .on(removeOrderRequestError, () => ({
    status: 'REJECT',
    data: null,
    error: new Error(),
  }));
