import { TIconProps } from './utils';

export const AccountIcon = ({ className = '' }: TIconProps) => (
  <svg
    width='46'
    height='46'
    viewBox='0 0 46 46'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M23.5751 10.3496C27.9834 10.3496 31.672 13.751 31.672 18.0028C31.672 20.5538 30.3225 22.8498 28.1634 24.2104C32.7516 25.826 36.1703 29.7376 36.8001 34.4996H34.461C33.7413 29.3975 29.063 25.5709 23.5751 25.5709C18.0872 25.5709 13.4089 29.3975 12.6892 34.4996H10.3501C10.9799 29.7376 14.3986 25.826 18.9868 24.2104C16.8276 22.8498 15.4782 20.5538 15.4782 18.0028C15.4782 13.751 19.1668 10.3496 23.5751 10.3496ZM23.5751 12.4755C20.3363 12.4755 17.8173 14.9415 17.8173 18.0028C17.8173 20.979 20.3363 23.445 23.5751 23.445C26.8139 23.445 29.3329 20.979 29.3329 18.0028C29.3329 14.9415 26.8139 12.4755 23.5751 12.4755Z'
      fill='white'
      stroke='white'
      strokeWidth='0.4'
    />
  </svg>
);
