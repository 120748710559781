import { createEvent, createStore } from 'effector';

import { TStore } from '../types';

export const $removeOrderStore = createStore<TStore<null>>({
  status: 'NONE',
  data: null,
  error: null,
});

export const startRemoveOrderRequest = createEvent();
export const endRemoveOrderRequest = createEvent();
export const removeOrderRequestError = createEvent();
