import { TIconProps } from './utils';

export const FavoriteNavIconHover = ({ className = '' }: TIconProps) => (
  <svg
    width='46'
    height='46'
    viewBox='0 0 46 46'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M29.2102 11.5C26.809 11.5 24.5044 12.6168 23.0002 14.3816C21.496 12.6168 19.1914 11.5 16.7902 11.5C12.5398 11.5 9.2002 14.8366 9.2002 19.0831C9.2002 24.2948 13.8922 28.5413 20.9992 34.9938L23.0002 36.8L25.0012 34.9801C32.1082 28.5413 36.8002 24.2948 36.8002 19.0831C36.8002 14.8366 33.4606 11.5 29.2102 11.5ZM23.1382 32.9395L23.0002 33.0774L22.8622 32.9395C16.2934 26.9971 11.9602 23.0677 11.9602 19.0831C11.9602 16.3256 14.0302 14.2575 16.7902 14.2575C18.9154 14.2575 20.9854 15.6225 21.7168 17.5113H24.2974C25.015 15.6225 27.085 14.2575 29.2102 14.2575C31.9702 14.2575 34.0402 16.3256 34.0402 19.0831C34.0402 23.0677 29.707 26.9971 23.1382 32.9395Z'
      fill='url(#paint0_linear_2181_47073)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_2181_47073'
        x1='6.8441'
        y1='1.79186'
        x2='45.4321'
        y2='13.112'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#B36BFF' />
        <stop offset='1' stopColor='#5A75FF' />
      </linearGradient>
    </defs>
  </svg>
);
