import { TIconProps } from './utils';

export const ArrowLinkIcon = ({ className = '' }: TIconProps) => (
  <svg
    width='8'
    height='24'
    viewBox='0 0 8 24'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M1.11473 17.3375C0.772701 16.987 0.772396 16.4277 1.11404 16.0768L3.91725 13.1976C4.29525 12.8093 4.29525 12.1907 3.91725 11.8024L1.11404 8.92319C0.772395 8.57228 0.772701 8.01301 1.11473 7.66248C1.46912 7.29926 2.05313 7.29926 2.40752 7.66248L6.4462 11.8016C6.82521 12.1901 6.82521 12.8099 6.4462 13.1984L2.40752 17.3375C2.05313 17.7007 1.46912 17.7007 1.11473 17.3375Z'
      fill='inherit'
    />
  </svg>
);
