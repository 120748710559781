import { TIconProps } from './utils';

export const FilterIcon = ({ className = '' }: TIconProps) => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
  >
    <path
      d='M2.1875 4.6875C2.1875 4.43886 2.28627 4.2004 2.46209 4.02459C2.6379 3.84877 2.87636 3.75 3.125 3.75H16.875C17.1236 3.75 17.3621 3.84877 17.5379 4.02459C17.7137 4.2004 17.8125 4.43886 17.8125 4.6875C17.8125 4.93614 17.7137 5.1746 17.5379 5.35041C17.3621 5.52623 17.1236 5.625 16.875 5.625H3.125C2.87636 5.625 2.6379 5.52623 2.46209 5.35041C2.28627 5.1746 2.1875 4.93614 2.1875 4.6875ZM4.6875 9.6875C4.6875 9.43886 4.78627 9.2004 4.96209 9.02459C5.1379 8.84877 5.37636 8.75 5.625 8.75H14.375C14.6236 8.75 14.8621 8.84877 15.0379 9.02459C15.2137 9.2004 15.3125 9.43886 15.3125 9.6875C15.3125 9.93614 15.2137 10.1746 15.0379 10.3504C14.8621 10.5262 14.6236 10.625 14.375 10.625H5.625C5.37636 10.625 5.1379 10.5262 4.96209 10.3504C4.78627 10.1746 4.6875 9.93614 4.6875 9.6875ZM7.1875 14.6875C7.1875 14.4389 7.28627 14.2004 7.46209 14.0246C7.6379 13.8488 7.87636 13.75 8.125 13.75H11.875C12.1236 13.75 12.3621 13.8488 12.5379 14.0246C12.7137 14.2004 12.8125 14.4389 12.8125 14.6875C12.8125 14.9361 12.7137 15.1746 12.5379 15.3504C12.3621 15.5262 12.1236 15.625 11.875 15.625H8.125C7.87636 15.625 7.6379 15.5262 7.46209 15.3504C7.28627 15.1746 7.1875 14.9361 7.1875 14.6875Z'
      fill='#636363'
    />
  </svg>
);
