import { TIconProps } from './utils';

export const ArrowAccIcon = ({ className = '' }: TIconProps) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M26.668 10.667L16.0013 21.3337L5.33464 10.667'
      stroke='black'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
