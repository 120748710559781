import { TIconProps } from './utils';

export const MenuOrdersIconActive = ({ className = '' }: TIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='29'
    height='32'
    viewBox='0 0 29 32'
    fill='none'
    className={className}
  >
    <path
      d='M13.2727 22.9767H14.5M13.2727 22.9767V29C13.2727 30.1046 12.3773 31 11.2727 31H8.61039C7.50582 31 6.61039 30.1046 6.61039 29V28.216C6.61039 27.7602 6.45465 27.318 6.16896 26.9627L1.44143 21.0838C1.15574 20.7286 1 20.2864 1 19.8305V10.2827C1 9.70421 1.2505 9.15403 1.68686 8.7742L3.80519 6.93023M13.2727 22.9767L7.14345 17.5567C7.02237 17.4496 6.88774 17.3589 6.74302 17.2869V17.2869C6.03495 16.9347 5.18115 17.073 4.6205 17.6308L3.80519 18.4419M15.7273 22.9767V29C15.7273 30.1046 16.6227 31 17.7273 31H20.3896C21.4942 31 22.3896 30.1046 22.3896 29V28.216C22.3896 27.7602 22.5454 27.318 22.831 26.9627L27.5586 21.0838C27.8443 20.7286 28 20.2864 28 19.8305V10.2733C28 9.70025 27.7542 9.15471 27.3249 8.77509L24.8442 6.58139M15.7273 22.9767H14.5M15.7273 22.9767L21.0472 17.6843C21.2389 17.4936 21.4672 17.3438 21.7183 17.2439L21.8714 17.183C22.6118 16.8883 23.4565 17.0613 24.0214 17.6234L24.8442 18.4419M24.8442 6.58139L15.2911 1.51279C14.6889 1.19327 13.9655 1.2025 13.3716 1.53728L3.80519 6.93023M24.8442 6.58139L14.3247 12.1628M24.8442 6.58139V18.4419M3.80519 6.93023L14.3247 12.1628M3.80519 6.93023V18.4419M14.3247 12.1628L14.5 22.9767M24.8442 18.4419L21.3377 23.6744M3.80519 18.4419L7.66234 23.6744'
      stroke='url(#paint0_linear_18_28)'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <defs>
      <linearGradient
        id='paint0_linear_18_28'
        x1='0.86471'
        y1='16.2055'
        x2='40.4986'
        y2='16.2055'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.28779' stopColor='#B36BFF' />
        <stop offset='0.859375' stopColor='#5A75FF' />
      </linearGradient>
    </defs>
  </svg>
);
