import { TIconProps } from './utils';

export const FavoriteNavIcon = ({ className = '' }: TIconProps) => (
  <svg
    width='46'
    height='46'
    viewBox='0 0 46 46'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M29.2099 11.5C26.8088 11.5 24.5041 12.6168 23 14.3816C21.4958 12.6168 19.1912 11.5 16.79 11.5C12.5396 11.5 9.19995 14.8366 9.19995 19.0831C9.19995 24.2948 13.892 28.5413 20.999 34.9938L23 36.8L25.0009 34.9801C32.1079 28.5413 36.7999 24.2948 36.7999 19.0831C36.7999 14.8366 33.4603 11.5 29.2099 11.5ZM23.138 32.9395L23 33.0774L22.8619 32.9395C16.2931 26.9971 11.96 23.0677 11.96 19.0831C11.96 16.3256 14.03 14.2575 16.79 14.2575C18.9152 14.2575 20.9851 15.6225 21.7165 17.5113H24.2971C25.0147 15.6225 27.0847 14.2575 29.2099 14.2575C31.9699 14.2575 34.0399 16.3256 34.0399 19.0831C34.0399 23.0677 29.7068 26.9971 23.138 32.9395Z'
      fill='white'
    />
  </svg>
);
