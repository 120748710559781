import { TIconProps } from './utils';

export const AccountMobIcon = ({ className = '' }: TIconProps) => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    width='45'
    height='45'
    viewBox='0 0 45 45'
    fill='none'
  >
    <path
      d='M27.4177 23.4729L26.9804 23.7485L27.468 23.9202C31.7845 25.4402 35.0131 29.0711 35.7112 33.5H33.9265C33.1042 28.4816 28.4659 24.7654 23.0625 24.7654C17.6591 24.7654 13.0208 28.4816 12.1985 33.5H10.4138C11.1119 29.0711 14.3405 25.4402 18.657 23.9202L19.1446 23.7485L18.7073 23.4729C16.6628 22.1847 15.3916 20.016 15.3916 17.6118C15.3916 13.6054 18.8728 10.375 23.0625 10.375C27.2522 10.375 30.7334 13.6054 30.7334 17.6118C30.7334 20.016 29.4622 22.1847 27.4177 23.4729ZM23.0625 11.9547C19.7643 11.9547 17.1798 14.4709 17.1798 17.6118C17.1798 20.672 19.7669 23.1857 23.0625 23.1857C26.3581 23.1857 28.9452 20.672 28.9452 17.6118C28.9452 14.4709 26.3607 11.9547 23.0625 11.9547Z'
      fill='white'
      stroke='#1C1C1C'
      strokeWidth='0.5'
    />
  </svg>
);
