import clsx from 'clsx';
import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Link, useNavigate } from 'react-router-dom';

import styles from './items-box.module.scss';
import { EBrands, IItemsBoxProps } from './types';

import { Item } from '..';
import { ArrowLinkIcon, BrandItem, Button, EButtonKinds, Paragraph, Title } from '../ui';

import { Navigation } from 'src/fork-components/swiper/modules/index.mjs';

import { Swiper, SwiperSlide } from 'src/fork-components/swiper/swiper-react';
import { TBrand, TCharacteristic } from '~utils';

export const ItemsBox: FC<IItemsBoxProps> = memo(({ type, data, className = '', ...rest }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isBrands = useMemo(() => type === EBrands.BRANDS, [type]);

  // Для мобильного слайдера у брендов разбиваем слайды на подмассивы по 4 штуки
  const mobBrandData = useMemo(
    () =>
      isBrands
        ? (data as TBrand[]).reduce<TBrand[][]>((acc, item, i) => {
            const index = Math.floor(i / 4);
            if (acc[index]) {
              acc[index].push(item);
              return acc;
            } else {
              return [...acc, [item]];
            }
          }, [])
        : [],
    [data, isBrands],
  );

  const handleClickToRedirect = () => navigate(`/${type}`);

  return (
    <section className={clsx(styles.container, className)} {...rest}>
      <Title className={styles.title}>{t(`items.${type}-title`)}</Title>
      {!isBrands && (
        <Link to={`/${type}`} className={styles.link}>
          <Paragraph className={styles.text}>
            {t(`items.${type}-action`)} <ArrowLinkIcon className={styles.icon} />
          </Paragraph>
        </Link>
      )}
      <div className={styles.swiper_box}>
        <Swiper
          slidesPerView='auto'
          spaceBetween={isBrands ? 24 : 20}
          centerInsufficientSlides
          navigation
          modules={[Navigation]}
          className={styles.swiper}
        >
          {data.map(item => (
            <SwiperSlide className={isBrands ? styles.slide_brand : styles.slide} key={item.id}>
              {type === EBrands.BRANDS ? (
                <BrandItem data={item as TBrand} />
              ) : (
                <Item data={item as TCharacteristic} />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <ul className={styles.list_mobile}>
        {isBrands ? (
          <Swiper
            slidesPerView='auto'
            spaceBetween={24}
            centerInsufficientSlides
            navigation
            modules={[Navigation]}
            className={styles.swiper}
          >
            {mobBrandData.map(item => (
              <SwiperSlide className={styles.slide} key={item[0].id}>
                {item.map(el => (
                  <BrandItem key={el.id} data={el as TBrand} />
                ))}
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          data.map((item, i) => {
            if (i > 6) return null;

            return <Item key={item.id} data={item as TCharacteristic} />;
          })
        )}
      </ul>
      {!isBrands && (
        <Button
          className={styles.btn_mobile}
          kind={EButtonKinds.load}
          text={t(`items.${type}-btn`)}
          onClick={handleClickToRedirect}
        />
      )}
    </section>
  );
});
