export interface IPersonalDataProps {
  className?: string;
}

export type TStatusData = {
  linePercent: number;
};

export enum EMode {
  READ = 'read',
  EDIT = 'edit',
}
