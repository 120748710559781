import { useStore } from 'effector-react';
import { useMemo } from 'react';

import { $cartItemsStore, $catalogItemsStore, $removeOrderStore } from 'src/models';

export const useLoader = () => {
  const { status: cartStatus } = useStore($cartItemsStore);
  const { status: catalogStatus } = useStore($catalogItemsStore);
  const { status: removeOrderStatus } = useStore($removeOrderStore);

  const isLoader = useMemo(
    () =>
      cartStatus === 'LOADING' || catalogStatus === 'LOADING' || removeOrderStatus === 'LOADING',
    [cartStatus, catalogStatus, removeOrderStatus],
  );

  return { isLoader };
};
