import { TIconProps } from './utils';

export const ArrowPagIcon = ({ className = '' }: TIconProps) => (
  <svg
    width='8'
    height='12'
    viewBox='0 0 8 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M6.50877 1.08882C6.67931 1.26935 6.67946 1.55155 6.50912 1.73225L3.45613 4.97112C2.91149 5.54892 2.91149 6.45108 3.45613 7.02888L6.50912 10.2677C6.67946 10.4485 6.67931 10.7307 6.50877 10.9112L6.87224 11.2545L6.50877 10.9112C6.3238 11.107 6.01224 11.107 5.82726 10.9112L1.51218 6.34335C1.33013 6.15065 1.33013 5.84935 1.51218 5.65665L5.82726 1.08882C6.01224 0.893014 6.3238 0.893013 6.50877 1.08882Z'
      fill='#CDCDCD'
      stroke='#CDCDCD'
    />
  </svg>
);
