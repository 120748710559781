import clsx from 'clsx';
import { forwardRef } from 'react';

import styles from './close-button.module.scss';

import { ICloseButtonProps } from './types';

import { Paragraph } from '..';

import { CrossIcon } from '../icons';

export const CloseButton = forwardRef<HTMLButtonElement, ICloseButtonProps>(
  ({ className = '', textClassName = '', iconClassName = '', text, icon: Icon, ...rest }, ref) => (
    <button className={clsx(styles.button, className)} ref={ref} {...rest}>
      {text && <Paragraph className={clsx(styles.text, textClassName)}>{text}</Paragraph>}
      {Icon ? (
        <Icon className={clsx(styles.icon, iconClassName)} />
      ) : (
        <CrossIcon className={clsx(styles.icon, iconClassName)} />
      )}
    </button>
  ),
);
