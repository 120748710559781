import { TIconProps } from './utils';

export const CartMobIcon = ({ className = '' }: TIconProps) => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    width='45'
    height='45'
    viewBox='0 0 45 45'
    fill='none'
  >
    <path
      d='M16.9875 14.8006H17.2375V14.5506V11.9458C17.2375 8.60139 19.8687 5.875 23.0625 5.875C26.2563 5.875 28.8875 8.60139 28.8875 11.9458V14.5506V14.8006H29.1375H33.8625C34.2915 14.8006 34.625 15.1333 34.625 15.5925V31.9155C34.625 34.6668 32.469 36.875 29.8125 36.875H16.3125C13.656 36.875 11.5 34.6668 11.5 31.9155V15.5925C11.5 15.1333 11.8335 14.8006 12.2625 14.8006H16.9875ZM27.1125 14.8006H27.3625V14.5506V11.9458C27.3625 9.49176 25.4387 7.45879 23.0625 7.45879C20.6863 7.45879 18.7625 9.49176 18.7625 11.9458V14.5506V14.8006H19.0125H27.1125ZM33.1 16.6691V16.4191H32.85H29.1375H28.8875V16.6691V18.4751C28.8875 18.9342 28.554 19.267 28.125 19.267C27.696 19.267 27.3625 18.9342 27.3625 18.4751V16.6691V16.4191H27.1125H19.0125H18.7625V16.6691V18.4751C18.7625 18.9342 18.429 19.267 18 19.267C17.571 19.267 17.2375 18.9342 17.2375 18.4751V16.6691V16.4191H16.9875H13.275H13.025V16.6691V31.9502C13.025 33.818 14.4802 35.3259 16.3125 35.3259H29.8125C31.6448 35.3259 33.1 33.818 33.1 31.9502V16.6691Z'
      fill='white'
      stroke='#1C1C1C'
      strokeWidth='0.5'
    />
  </svg>
);
